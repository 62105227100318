/**
 * These are internal enum Ids stored with some components' fields (when a component has multiple fields).
 * This is used to identify which field options belong to a particular field in that singular component.
 */
export var FormFieldInternalId;
(function (FormFieldInternalId) {
  FormFieldInternalId[FormFieldInternalId["CustomerFirstNameField"] = 0] = "CustomerFirstNameField";
  FormFieldInternalId[FormFieldInternalId["CustomerLastNameField"] = 1] = "CustomerLastNameField";
  FormFieldInternalId[FormFieldInternalId["CustomerEmailField"] = 2] = "CustomerEmailField";
  FormFieldInternalId[FormFieldInternalId["CustomerPhoneField"] = 3] = "CustomerPhoneField";
  FormFieldInternalId[FormFieldInternalId["CustomerStreet1Field"] = 4] = "CustomerStreet1Field";
  FormFieldInternalId[FormFieldInternalId["CustomerStreet2Field"] = 5] = "CustomerStreet2Field";
  FormFieldInternalId[FormFieldInternalId["CustomerCityField"] = 6] = "CustomerCityField";
  FormFieldInternalId[FormFieldInternalId["CustomerCountryField"] = 7] = "CustomerCountryField";
  FormFieldInternalId[FormFieldInternalId["CustomerRegionField"] = 8] = "CustomerRegionField";
  FormFieldInternalId[FormFieldInternalId["CustomerPostalCodeField"] = 9] = "CustomerPostalCodeField";
  FormFieldInternalId[FormFieldInternalId["BookingWidgetTitle"] = 10] = "BookingWidgetTitle";
  FormFieldInternalId[FormFieldInternalId["BookingWidgetServiceLabel"] = 11] = "BookingWidgetServiceLabel";
  FormFieldInternalId[FormFieldInternalId["BookingWidgetDateLabel"] = 12] = "BookingWidgetDateLabel";
  FormFieldInternalId[FormFieldInternalId["BookingWidgetTimeLabel"] = 13] = "BookingWidgetTimeLabel";
  FormFieldInternalId[FormFieldInternalId["BookingWidgetServicesData"] = 14] = "BookingWidgetServicesData";
})(FormFieldInternalId || (FormFieldInternalId = {}));